import * as React from "react";
import {IBulkFormData} from "../../../../../../config/bulk";
import {FormModal} from "../../../../../Components/FormModal";
import {useTranslation} from "react-i18next";
import {TransactionForm} from "../../../../Components/Form/TransactionForm";
import {TransactionFormField, TransactionFormScope} from "../../../../Components/Form/config/config";
import {ITransactionFormSubmit} from "../../../../Components/Form/config/models";
import {validateTransactionForm} from "../../../../../../validators/transactions";
import {
    convertFromTransactionFormInputData,
    convertToTransactionFormInputData
} from "../../../../../../helpers/bulk_helpers";

interface IEditDataProps {
    data: IBulkFormData;
    onSave(data: IBulkFormData): void;
    onClose(): void;
}

export const EditData = ({data, onSave, onClose}: IEditDataProps) => {
    const { t } = useTranslation();

    const handleEditSubmit = (output: ITransactionFormSubmit): Promise<any> => {
        const validation = validateTransactionForm(output.data);
        if (!validation.isValid()) {
            return Promise.reject(validation.getErrors());
        }

        onClose();

        return Promise.resolve(convertFromTransactionFormInputData(output.data));
    };

    const handleError = () => {
    };

    return (
        <FormModal
            title={
                data.errors === null ?
                    t('transactions.bulk.check.button_edit') :
                    t('transactions.bulk.check.button_fix')
            }
            open={true}
            loading={false}
            onClose={onClose}
        >
            <TransactionForm
                scope={TransactionFormScope.EDIT}
                fields={[
                    TransactionFormField.CURRENCY,
                    TransactionFormField.AMOUNT,
                    TransactionFormField.DATE,
                    TransactionFormField.DESCRIPTION,
                    TransactionFormField.PAYMENT_METHOD,
                    TransactionFormField.LOCATION,
                    TransactionFormField.CATEGORY,
                ]}
                transaction={convertToTransactionFormInputData(data)}
                onSubmit={handleEditSubmit}
                successfulCallback={onSave as any}
                unsuccessfulCallback={handleError}
            />
        </FormModal>
    );
};
