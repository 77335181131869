import {Currency} from "./currencies";

export enum RecurringExpensesTimeFrequency {
    DAILY = "DAILY",
    WEEKLY = "WEEKLY",
    MONTHLY = "MONTHLY",
    YEARLY = "YEARLY",
}

export interface IRecurringExpensesStats {
    DAILY: number;
    WEEKLY: number;
    MONTHLY: number;
    YEARLY: number;
    details: {
        MONTHLY: number[];
    };
}

export interface IRecurringExpenseID {
    id: number;
}

export interface IRecurringExpense {
    id: number;
    income_id?: number;
    description: string;
    time_frequency: RecurringExpensesTimeFrequency;
    currency: Currency;
    amount: number;
    category_id: number | null;
    location_id?: number | null;
    location_name?: string | null;
    payment_method_id: number | null;
    active: boolean;
    withdraw_time: number | null;
}

export interface IRecurringExpenseCreate {
    income_id?: number | null;
    description: string;
    time_frequency: RecurringExpensesTimeFrequency;
    currency: Currency;
    amount: number;
    category_id: number | null;
    location_id?: number | null;
    payment_method_id: number | null;
    active: boolean;
    withdraw_time: number | null;
}

export interface IRecurringExpenseUpdate {
    income_id?: number | null;
    description?: string;
    time_frequency?: RecurringExpensesTimeFrequency;
    currency?: Currency;
    amount?: number;
    category_id?: number | null;
    location_id?: number | null;
    payment_method_id?: number | null;
    active?: boolean;
    withdraw_time: number | null;
}

const RecurringExpensesTimeFrequencies = [
    {
        code: RecurringExpensesTimeFrequency.DAILY,
        name: 'Daily',
    },
    {
        code: RecurringExpensesTimeFrequency.WEEKLY,
        name: 'Weekly',
    },
    {
        code: RecurringExpensesTimeFrequency.MONTHLY,
        name: 'Monthly',
    },
    {
        code: RecurringExpensesTimeFrequency.YEARLY,
        name: 'Yearly',
    },
];

const RecurringExpensesTimeFrequencyLabels = {
    [RecurringExpensesTimeFrequency.DAILY]: 'Daily',
    [RecurringExpensesTimeFrequency.WEEKLY]: 'Weekly',
    [RecurringExpensesTimeFrequency.MONTHLY]: 'Monthly',
    [RecurringExpensesTimeFrequency.YEARLY]: 'Yearly',
}

export {RecurringExpensesTimeFrequencies, RecurringExpensesTimeFrequencyLabels}
