import {ReactElement} from "react";
import {CommissionFeeField} from "../components/Transactions/Components/Form/Fields/Details/Fields/CommissionFeeField";
import {FlightNumberField} from "../components/Transactions/Components/Form/Fields/Details/Fields/FlightNumberField";
import {FuelDetailsField} from "../components/Transactions/Components/Form/Fields/Details/Fields/FuelDetailsField";
import {
    MerchantTransactionIdField
} from "../components/Transactions/Components/Form/Fields/Details/Fields/MerchantTransactionIdField";
import {OrderIdField} from "../components/Transactions/Components/Form/Fields/Details/Fields/OrderIdField";
import {
    OrderedOnPlatformField
} from "../components/Transactions/Components/Form/Fields/Details/Fields/OrderedOnPlatformField";
import {PaidViaField} from "../components/Transactions/Components/Form/Fields/Details/Fields/PaidViaField";
import {TagsField} from "../components/Transactions/Components/Form/Fields/Details/Fields/TagsField";
import {
    VehicleForHireField
} from "../components/Transactions/Components/Form/Fields/Details/Fields/VehicleForHire/VehicleForHireField";
import {ItemValue} from "../components/Transactions/View/Details/Components/ItemValue";
import {FuelDetails} from "../components/Transactions/View/Details/FuelDetails";
import {PaidVia} from "../components/Transactions/View/Details/PaidVia";
import {VehicleForHire} from "../components/Transactions/View/Details/VehicleForHire";
import { LineItemsField } from "../components/Transactions/Components/Form/Fields/Details/Fields/LineItems/LineItemsField";
import { LineItems } from "../components/Transactions/View/Details/LineItems";
import SellIcon from '@mui/icons-material/Sell';
import FlightIcon from '@mui/icons-material/Flight';
import LocalGasStationIcon from '@mui/icons-material/LocalGasStation';
import StorefrontIcon from '@mui/icons-material/Storefront';
import ReceiptIcon from '@mui/icons-material/Receipt';
import LanguageIcon from '@mui/icons-material/Language';
import PaymentsIcon from '@mui/icons-material/Payments';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import LocalGroceryStoreIcon from '@mui/icons-material/LocalGroceryStore';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
import { ExtraPaidVia } from "../components/Transactions/View/MobileView/Fields/ExtraPaidVia";
import { ExtraCommissionFee } from "../components/Transactions/View/MobileView/Fields/ExtraCommissionFee";
import { FuelDetails as FuelDetailsV3 } from "../components/Transactions/View/MobileView/Fields/FuelDetails";
import { ExtraVehicleForHire } from "../components/Transactions/View/MobileView/Fields/ExtraVehicleForHire";
import { ExtraLineItems } from "../components/Transactions/View/MobileView/Fields/ExtraLineItems";
import { NotesField } from "../components/Transactions/Components/Form/Fields/Details/Fields/NotesField";
import { Notes } from "../components/Transactions/View/MobileView/Fields/Notes";

export enum TransactionDetailFields {
    AMOUNT_DETAILS = 'amount_details',
    COMMISSION_FEE = 'commission_fee',
    FLIGHT_NUMBER = 'flight_number',
    FUEL_DETAILS = 'fuel_details',
    MERCHANT_TRANSACTION_ID = 'merchant_transaction_id',
    ORDER_ID = 'order_id',
    ORDERED_ON = 'ordered_on',
    PAID_VIA = 'paid_via',
    TAGS = 'tags',
    VEHICLE_FOR_HIRE = 'vehicle_for_hire',
    LINE_ITEMS = 'line_items',
    NOTES = 'notes',
}

export interface ITransactionDetailFormField {
    code: TransactionDetailFields,
    label: string;
    element: ReactElement | any;
}

interface ITransactionDetailDisplayFields {
    [key: string]: {
        label: string;
        element: ReactElement | any;
        v3: {
            type: "standard" | "standalone";
            component?: ReactElement | any;
            icon: ReactElement | any;
        };
    };
}

export const detailsFormFields = [
    {
        code: TransactionDetailFields.COMMISSION_FEE,
        label: 'Commission Fee',
        element: CommissionFeeField,
    },
    {
        code: TransactionDetailFields.FLIGHT_NUMBER,
        label: 'Flight Number',
        element: FlightNumberField,
    },
    {
        code: TransactionDetailFields.FUEL_DETAILS,
        label: 'Fuel details',
        element: FuelDetailsField,
    },
    {
        code: TransactionDetailFields.MERCHANT_TRANSACTION_ID,
        label: 'Merchant Transaction ID',
        element: MerchantTransactionIdField,
    },
    {
        code: TransactionDetailFields.ORDER_ID,
        label: 'Order ID',
        element: OrderIdField,
    },
    {
        code: TransactionDetailFields.ORDERED_ON,
        label: 'Ordered on',
        element: OrderedOnPlatformField,
    },
    {
        code: TransactionDetailFields.PAID_VIA,
        label: 'Paid via',
        element: PaidViaField,
    },
    {
        code: TransactionDetailFields.TAGS,
        label: 'Tags',
        element: TagsField,
    },
    {
        code: TransactionDetailFields.VEHICLE_FOR_HIRE,
        label: 'Vehicle for hire',
        element: VehicleForHireField,
    },
    {
        code: TransactionDetailFields.LINE_ITEMS,
        label: 'Line items',
        element: LineItemsField,
    },
    {
        code: TransactionDetailFields.NOTES,
        label: 'Notes',
        element: NotesField,
    },
] as ITransactionDetailFormField[];

export const detailsAutomaticDisplayFields = {
    [TransactionDetailFields.COMMISSION_FEE]: {
        label: 'Commission Fee',
        element: ItemValue,
        v3: {
            type: "standard",
            component: ExtraCommissionFee,
            icon: SellIcon,
        },
    },
    [TransactionDetailFields.FLIGHT_NUMBER]: {
        label: 'Flight Number',
        element: ItemValue,
        v3: {
            type: "standard",
            icon: FlightIcon,
        },
    },
    [TransactionDetailFields.FUEL_DETAILS]: {
        label: 'Fuel Details',
        element: FuelDetails,
        v3: {
            type: "standard",
            component: FuelDetailsV3,
            icon: LocalGasStationIcon,
        },
    },
    [TransactionDetailFields.MERCHANT_TRANSACTION_ID]: {
        label: 'Merchant Transaction ID',
        element: ItemValue,
        v3: {
            type: "standard",
            icon: StorefrontIcon,
        },
    },
    [TransactionDetailFields.ORDER_ID]: {
        label: 'Order ID',
        element: ItemValue,
        v3: {
            type: "standard",
            icon: ReceiptIcon,
        },
    },
    [TransactionDetailFields.ORDERED_ON]: {
        label: 'Ordered on',
        element: ItemValue,
        v3: {
            type: "standard",
            icon: LanguageIcon,
        },
    },
    [TransactionDetailFields.PAID_VIA]: {
        label: 'Paid via',
        element: PaidVia,
        v3: {
            type: "standard",
            component: ExtraPaidVia,
            icon: PaymentsIcon,
        },
    },
    [TransactionDetailFields.VEHICLE_FOR_HIRE]: {
        label: '',
        element: VehicleForHire,
        v3: {
            type: "standalone",
            component: ExtraVehicleForHire,
            icon: DirectionsCarIcon,
        },
    },
    [TransactionDetailFields.LINE_ITEMS]: {
        label: 'Line items',
        element: LineItems,
        v3: {
            type: "standalone",
            component: ExtraLineItems,
            icon: LocalGroceryStoreIcon,
        },
    },
    [TransactionDetailFields.NOTES]: {
        label: 'Notes',
        element: Notes,
        v3: {
            type: "standard",
            icon: TextSnippetIcon,
        },
    },
} as ITransactionDetailDisplayFields;
