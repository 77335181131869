import * as React from "react";

interface IFreeContentFieldProps {
    content: React.ReactElement | string;
}

export const FreeContentField = ({ content }: IFreeContentFieldProps) => {
    return (
        <div>
            {content}
        </div>
    );
}
