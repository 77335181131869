import * as React from "react";
import Box from "@mui/material/Box";
import {PageItem} from "../../../Components/PageItem";
import {PageOuterContainer} from "../../../Components/PageOuterContainer";
import { ActualMonthsRecurringExpenses } from "./ActualMonthsRecurringExpenses";
import { NewRecurringExpense } from "../../New/NewRecurringExpense";

interface IContainerProps {
    smallScreen: boolean;
    children: React.ReactNode;
}

export const Container = ({smallScreen, children}: IContainerProps) => {
    if (smallScreen) {
        return (
            <div>
                {children}
            </div>
        )
    }

    return (
        <PageOuterContainer>
            <PageItem
                title="Recurring Expenses List"
                actions={
                    <NewRecurringExpense />
                }
            >
                <ActualMonthsRecurringExpenses />

                <Box sx={{display: 'flex', flexGrow: 1}}>
                    {children}
                </Box>
            </PageItem>
        </PageOuterContainer>
    )
};
