import * as React from "react";
import {FreeTextField} from "../../../../../../Components/Form/FreeTextField";
import {FieldWrapper} from "./FieldWrapper";

interface INotesFieldProps {
    value: string;
    onChange(value: string): void;
    onDelete(): void;
}

export const NotesField = ({value, onChange, onDelete}: INotesFieldProps) => (
    <FieldWrapper onDelete={onDelete}>
        <FreeTextField label="Notes" value={value} onChange={onChange} multiline rows={4} />
    </FieldWrapper>
);
