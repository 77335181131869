import { ITransaction } from "../../../../../config/transactions";
import { ItemValue } from "../../Details/Components/ItemValue";

interface INotesProps {
    value: string;
    transaction: ITransaction;
}

export const Notes = ({value, transaction}: INotesProps) => (
    <ItemValue
        transaction={transaction}
        label="Notes"
        value={
            <div dangerouslySetInnerHTML={{__html: `${value.replace(/\n/g, '<br />')}`}} />
        }
    />
);
