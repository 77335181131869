import * as React from "react";
import {IBulkFormData} from "../../../../../../config/bulk";
import {styled, Table, TableBody, TableCell, TableContainer, TableRow} from "@mui/material";
import {DataTableHeader} from "./DataTableHeader";
import {useTranslation} from "react-i18next";
import Typography from "@mui/material/Typography";
import {AutoFixHigh, Delete, Edit, RestoreFromTrash} from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import {grey} from "@mui/material/colors";
import {useState} from "react";
import {EditData} from "./EditData";
import {useGlobalStores} from "../../../../../../hooks/use_global_stores";

const StyledTableContainer = styled(TableContainer)(({theme}) => ({
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
}));

const StyledTableRow = styled(TableRow, {
    shouldForwardProp: (prop) => prop !== "deleted",
})<{deleted: boolean}>(({deleted, theme}) => ({
    ...(!deleted ? {} : {
        '& .MuiTableCell-root': {
            cursor: 'default',
            color: grey[300],
            transition: theme.transitions.create('color', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
        },
    }),
}));

const Errors = styled(Typography)(({theme}) => ({
    marginTop: theme.spacing(1.5),
    color: 'red',
}));

interface IItemProps {
    item: IBulkFormData;
    onEdit(): void;
    onToggleDelete(): void;
}

const Item = ({item, onEdit, onToggleDelete}: IItemProps) => {
    const { t } = useTranslation();
    const { userPaymentMethodStore, categoryStore } = useGlobalStores();

    return (
        <StyledTableRow deleted={!!item.isDeleted}>
            <TableCell>
                <IconButton onClick={onEdit} size="small" color="primary" disabled={!!item.isDeleted && item.isDeleted}>
                    {!!item.errors?.length ? <AutoFixHigh /> : <Edit />}
                </IconButton>

                <IconButton onClick={onToggleDelete} size="small" color="primary">
                    {!!item.isDeleted && item.isDeleted ? <RestoreFromTrash /> : <Delete />}
                </IconButton>
            </TableCell>
            <TableCell>{item.date.format("DD/MM/YYYY")}</TableCell>
            <TableCell>{(item.amount as any).toCurrency(item.currency)}</TableCell>
            <TableCell>
                {item.location?.name}
            </TableCell>
            <TableCell>
                {!!item.categoryId ? categoryStore.getById(item.categoryId)?.name : ''}
            </TableCell>
            <TableCell>{!!item.paymentMethod ? userPaymentMethodStore.getById(parseInt(item.paymentMethod))?.name : ''}</TableCell>
            <TableCell>
                {item.description}
                {!!item.errors &&
                    <Errors variant="subtitle2">
                        {t('transactions.bulk.check.errors', { errors: item.errors!!.join(', ') })}
                    </Errors>
                }
            </TableCell>
        </StyledTableRow>
    );
}

interface IDataTableProps {
    data: IBulkFormData[];
    onEdit(data: IBulkFormData, index: number): void;
    onDelete(index: number): void;
}

interface ISelection {
    index: number;
    data: IBulkFormData;
}

export const DataTable = ({data, onEdit, onDelete}: IDataTableProps) => {
    const [editOpen, setEditOpen] = useState<boolean>(false);
    const [selection, setSelection] = useState<ISelection>();

    const handleOpenEdit = (data: IBulkFormData, index: number) => () => {
        setEditOpen(true);
        setSelection({
            index, data,
        });
    };

    const handleCloseEdit = () => {
        setEditOpen(false);
    };

    const handleEditSave = ({index}: ISelection) => (data: IBulkFormData) => {
        onEdit(data, index);
    };

    const handleDelete = (index: number) => () => {
        onDelete(index);
    };

    return (
        <>
            <StyledTableContainer>
                <Table size="small">
                    <DataTableHeader />
                    <TableBody>
                        {data.map((item, index) => (
                            <Item
                                item={item}
                                onEdit={handleOpenEdit(item, index)}
                                onToggleDelete={handleDelete(index)}
                                key={index}
                            />
                        ))}
                    </TableBody>
                </Table>
            </StyledTableContainer>

            {editOpen && selection && <EditData data={selection.data} onSave={handleEditSave(selection)} onClose={handleCloseEdit} />}
        </>
    );
};
