import * as React from "react";
import {useRecurringExpenseStore} from "../../../../hooks/use_recurring_expense_store";
import {useGlobalStores} from "../../../../hooks/use_global_stores";
import {alpha, Box, styled, Typography} from "@mui/material";
import {observer} from "mobx-react";
import moment from "moment";
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import { calculateAverage, getMaximumMonthRecurringExpense, getMinimumMonthRecurringExpense } from "../../../../helpers/recurring_expenses_helper";
import { RecurringExpensesTimeFrequency, RecurringExpensesTimeFrequencyLabels } from "../../../../config/recurring_expense";

const Container = styled(Box)(({theme}) => ({
    marginBottom: theme.spacing(4),
}));

const List = styled('ul')(() => ({
    margin: 0,
    padding: 0,
    listStyle: 'none',
    display: 'flex',
    justifyContent: 'space-between',
}));

const Item = styled('li', {
    shouldForwardProp: (prop) => prop !== "condensed",
})<{current?: boolean}>(({current, theme}) => ({
    margin: 0,
    padding: theme.spacing(1),
    borderRadius: 5,
    backgroundColor: alpha(theme.palette.primary.main, 0.15),

    border: 'solid 2px',
    borderColor: current ? theme.palette.primary.main : 'transparent',

    '&:hover': {
        backgroundColor: alpha(theme.palette.primary.main, 0.2),
    },
}));

const Separator = styled('li')(({theme}) => ({
    width: 2,
    backgroundColor: theme.palette.primary.main,
}));

const InfoContainer = styled(Box)(() => ({
    display: 'flex',
    justifyContent: 'space-between',
}));

const Title = styled(Typography, {
    shouldForwardProp: (prop) => prop !== "condensed",
})<{current?: boolean, average?: boolean}>(({current, average}) => ({
    fontSize: 10,
    fontWeight: current ? 'bold' : 100,

    ...(!!average ? {
            fontStyle: 'italic',
            '&::first-letter': {
                textTransform: 'capitalize',
            },
    } : {}),
}));

const Amount = styled(Typography)(() => ({
}));

const Icon = styled(Typography, {
    shouldForwardProp: (prop) => prop !== "condensed",
})<{isMax: boolean}>(({isMax, theme}) => ({
    lineHeight: 0,

    '> *': {
        fontSize: 14,
        color: isMax ? 'red' : 'auto',
    },
}));

export const ActualMonthsRecurringExpenses = observer(() => {
    const { currencyStore, generalSettings } = useGlobalStores();
    const recurringExpenseStore = useRecurringExpenseStore();

    const currency = currencyStore.currentCurrency;

    const stats = recurringExpenseStore.stats;
    if (stats === undefined || !stats.details.MONTHLY.length) {
        return null;
    }

    const min = getMinimumMonthRecurringExpense(stats.details.MONTHLY);
    const max = getMaximumMonthRecurringExpense(stats.details.MONTHLY);
    const currentMonth = moment().month();

    return (
        <Container>
            <List>
                <Item>
                    <Amount>{(calculateAverage(stats, RecurringExpensesTimeFrequency[generalSettings.settings.defaultRecurringExpensesAggregator]) as any).toCurrency(currency)}</Amount>
                    <InfoContainer>
                        <Title average>
                            {RecurringExpensesTimeFrequencyLabels[generalSettings.settings.defaultRecurringExpensesAggregator].toLowerCase()} average
                        </Title>
                    </InfoContainer>
                </Item>
                <Separator />

                {stats.details.MONTHLY.map((v, i) => (
                    <Item key={i} current={i === currentMonth}>
                        <Amount>{(v as any).toCurrency(currency)}</Amount>
                        <InfoContainer>
                            <Title current={i === currentMonth}>{moment(`2024-${i+1}-01`).format('MMMM')}</Title>
                            {min < v && <Icon isMax={max === v}><ArrowUpwardIcon /></Icon>}
                        </InfoContainer>
                    </Item>
                ))}

                
            </List>
        </Container>
    );
});
