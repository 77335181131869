import * as React from "react";
import {styled} from "@mui/material";
import { ILineItem, LineItemsDisplayScope } from "../../Components/Form/Fields/Details/Fields/LineItems/LineItemsField";
import { DataTable } from "../../Components/Form/Fields/Details/Fields/LineItems/DataTable";
import { ItemValue } from "./Components/ItemValue";
import { ITransaction } from "../../../../config/transactions";

export const Container = styled('div')(() => ({
    maxWidth: 500,

    '.details-list-item-label': {
        width: 120,
    },

    '.details-list-item-value': {
        width: 400,
        backgroundColor: 'red',
    },
}));

interface ILineItemsProps {
    label: string;
    transaction: ITransaction;
    value: ILineItem[];
}

export const LineItems = ({label, transaction, value}: ILineItemsProps) => {
    return (
        <ItemValue transaction={transaction} label={label} value={<DataTable
            scope={LineItemsDisplayScope.TRANSACTION_VIEW}
            value={value}
            onChange={() => {}}
            currency={transaction.original_currency}
        />} />
    );
};
