import * as React from "react";
import {alpha, styled, Table, TableBody, TableCell, TableContainer, TableRow} from "@mui/material";
import {IRecurringExpense, RecurringExpensesTimeFrequency} from "../../../config/recurring_expense";
import {TableFooter} from "./Components/TableFooter";
import {TableHeader} from "./Components/TableHeader";
import {PaymentMethodField} from "./TableFields/PaymentMethodField";
import {CategoryField} from "./TableFields/CategoryField";
import {LocationField} from "./TableFields/LocationField";
import {ActiveToggleAction} from "./Actions/ActiveToggleAction";
import {EditAction} from "./Actions/EditAction";
import {DeleteAction} from "./Actions/DeleteAction";
import { useGlobalStores } from "../../../hooks/use_global_stores";
import { categorizeIntoIncomes } from "../../../helpers/recurring_expenses_helper";

const StyledTableContainer = styled(TableContainer)(({theme}) => ({
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
    },
}));

const ActionsCell = styled(TableCell)(() => ({
    whiteSpace: 'nowrap',
    paddingRight: 0,
    width: 10,
    textAlign: 'center',
}));

const PaymentMethodCell = styled(TableCell)(({theme}) => ({
    [theme.breakpoints.down('sm')]: {
        display: 'none',
    },
}));

const CategoryCell = styled(TableCell)(({theme}) => ({
    [theme.breakpoints.down('sm')]: {
        display: 'none',
    },
}));

const LocationCell = styled(TableCell)(({theme}) => ({
    [theme.breakpoints.down('sm')]: {
        display: 'none',
    },
}));


const IncomeCell = styled(TableCell)(({theme}) => ({
    backgroundColor: alpha(theme.palette.primary.main, 0.10),
    color: theme.palette.primary.main,
    fontWeight: 'bold',
}));

interface IStyledTableRow {
    active: boolean;
}

const StyledTableRow = styled(TableRow, {
    shouldForwardProp: (prop) => prop !== "active",
})<IStyledTableRow>(({active}) => ({
    ...(!active ? {
        background: 'repeating-linear-gradient(\n' +
            '    -60deg,\n' +
            '    #fff,\n' +
            '    #fff 10px,\n' +
            '    #efefef 10px,\n' +
            '    #efefef 20px\n' +
            ');'
    } : {}),
}));

interface IDataTableProps {
    data: IRecurringExpense[];
    timeFrequency: RecurringExpensesTimeFrequency;
}

export const DataTable = ({data, timeFrequency}: IDataTableProps) => {
    const { incomeStore } = useGlobalStores();

    const categoriezed = categorizeIntoIncomes(incomeStore.incomes, data);

    return (
        <StyledTableContainer>
            <Table size="small">
                <TableHeader />
                <TableBody>
                    {
                        categoriezed.map((item, index) => (
                            <React.Fragment key={index}>
                                <TableRow>
                                    <IncomeCell colSpan={6}>
                                        {!!item.income ? item.income.name : 'Other'}
                                    </IncomeCell>
                                </TableRow>

                                {item.recurringExpenses.map((item, index) => (
                                    <StyledTableRow active={item.active} key={index}>
                                        <ActionsCell>
                                            <ActiveToggleAction recurringExpense={item} />
                                            <EditAction recurringExpense={item} />
                                            <DeleteAction recurringExpense={item} />
                                        </ActionsCell>
                                        <TableCell>{item.description}</TableCell>
                                        <TableCell>{(item.amount as any).toCurrency(item.currency as any)}</TableCell>
                                        <PaymentMethodCell>
                                            <PaymentMethodField paymentMethodId={item.payment_method_id} />
                                        </PaymentMethodCell>
                                        <CategoryCell>
                                            <CategoryField categoryId={item.category_id} />
                                        </CategoryCell>
                                        <LocationCell>
                                            <LocationField locationName={item.location_name} />
                                        </LocationCell>
                                    </StyledTableRow>
                                ))}
                            </React.Fragment>
                        ))
                    }

                    <TableFooter timeFrequency={timeFrequency} />
                </TableBody>
            </Table>
        </StyledTableContainer>
    )
};
