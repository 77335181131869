import * as React from "react";
import {Button} from "@mui/material";
import {useModal} from "../../../../hooks/use_modal";
import {Form} from "../../../Components/Form/Form/Form";
import {FormModal} from "../../../Components/FormModal";
import {useForm} from "../../../../hooks/use_form_v2";
import { CheckboxField } from "../../../Components/Form/CheckboxField";
import { populateLogos } from "../../stores/locations";
import { FreeContentField } from "../../../Components/Form/FreeContentField";

interface IPopulateLogosActionState {
    confirm: boolean;
}

export const PopulateLogosAction = () => {
    const { loading, open, handleModalOpen, handleModalClose } = useModal();
    const form = useForm<IPopulateLogosActionState>();
    const { register } = form;

    const handleSubmit = async (data: IPopulateLogosActionState): Promise<any> => {
        if (data.confirm !== true) {
            alert('OOOOPS!');
            return;
        }

        await populateLogos();
        handleModalClose();

        return await Promise.resolve();
    };

    return (
        <>
            <Button variant="contained" color="primary" onClick={handleModalOpen}>
                Populate Logos
            </Button>

            <FormModal
                title="Populate Logos"
                loading={loading}
                open={open}
                onClose={handleModalClose}
            >
                <Form form={form} onSubmit={handleSubmit}>
                    <Form.Field
                        type={FreeContentField}
                        content={
                            <div>
                                This operation can take a long time and rebuilds the redis index from scratch.<br />
                                Are you use you want to proceed?
                            </div>
                        }
                    />
                    <Form.Field
                        type={CheckboxField}
                        {...register('confirm')}
                    />
                </Form>
            </FormModal>
        </>
    );
};
