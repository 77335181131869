import * as React from "react";
import { CloudUpload } from "@mui/icons-material";
import { CircularProgress, Grid } from "@mui/material";
import { VisuallyHiddenField } from "../../../../../Components/Form/VisuallyHiddenField";
import { analyzeReceipt } from "../../../../../../stores/transactions";
import { IReceiptData } from "../../../../../../config/transactions";
import { useTransactionFormStore } from "../../../../../../hooks/use_transaction_form_store copy";
import { AttachmentType } from "../../../../../../config/attachments";
import { ProButton } from "../../../../../Pro/Components/ProButton";
import { Level } from "../../../../../../config/auth";
import { Feature } from "../../../../../../config/features";

interface IUploadReceiptProps {
    onLoad(): void; // da eseguire quando la richiesta http parte
    onChange(value: IReceiptData): void;
}

export const UploadReceipt = ({onLoad, onChange}: IUploadReceiptProps) => {
    const { pushAttachmentStore } = useTransactionFormStore();

    const [loading, setLoading] = React.useState<boolean>(false);
    const [file, setFile] = React.useState<File>();

    const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (!!event.target.files!![0]) {
            setFile(event.target.files!![0]);
        }
    };

    React.useEffect(() => {
        if (!file) {
            return;
        }

        onLoad();
        setLoading(true);

        const formData = new FormData();
        formData.append('file', file, file!!.name);

        analyzeReceipt(formData)
            .then(res => {
                onChange(res as IReceiptData);

                pushAttachmentStore.addNewAttachment({
                    type: AttachmentType.RECEIPT,
                    file: file,
                });
            })
            .finally(() => setLoading(false));
    }, [file]);

    return (
        <Grid item xs={12} sx={{marginTop: -2, marginBottom: 2}}>
            <ProButton min={Level.PLATINUM} feature={Feature.TRANSACTIONS_UPLOAD_RECEIPT} text="Upload receipt" disabled={loading} component="label" variant="contained" startIcon={!loading ? <CloudUpload /> : <CircularProgress size={20} />}>
                Upload receipt
                <VisuallyHiddenField onChange={handleFileChange} type="file" accept={"image/*, application/pdf"} capture />
            </ProButton>
        </Grid>
    );
};
