import * as React from "react";
import {Button, Grid} from "@mui/material";
import {useTranslation} from "react-i18next";
import {useEffect, useState} from "react";
import CircularProgress from "@mui/material/CircularProgress";
import {IBulkConfig} from "../../../../../../config/bulk";
import {bulkUpload, getBulkConfig} from "../../../../../../stores/bulk";
import {FileField} from "./FileField";
import {getFormatFromFile} from "../../../../../../helpers/bulk_helpers";
import {useGlobalStores} from "../../../../../../hooks/use_global_stores";
import {FormWrapper} from "../../../../../Components/Form/FormWrapper";
import {SelectField} from "../../../../../Components/Form/SelectField";

const LABELS = {
    de_amazon_visa: 'Amazon Visa (Legacy)',
    de_amazon_visa_zinia: 'Amazon Visa (Zinia)',
    de_amex: 'American Express',
    de_dkb: 'DKB',
    de_n26: 'N26',
    global_es: 'Expenses Manager',
} as any;

interface ISelectFormStepProps {
    onSubmit(promise: Promise<any>): void;
}

interface IBulkImportState {
    region: string;
    source: string;
    format: string;
    file: File | null;
}

const DEFAULT_REGION = 'de';

export const SelectFormStep = ({onSubmit}: ISelectFormStepProps) => {
    const { t } = useTranslation();

    const { snackbarStore } = useGlobalStores();

    const [values, setValues] = useState<IBulkImportState>({
        region: DEFAULT_REGION,
        source: '',
        format: '',
        file: null,
    });
    const [loading, setLoading] = useState(false);
    const [configLoading, setConfigLoading] = useState(false);
    const [config, setConfig] = useState<IBulkConfig | null>();

    const handleChange = (prop: keyof IBulkImportState) => (value: any) => {
        setValues({ ...values, [prop]: value });
    };

    const handleFileChange = (file: File) => {
        const format = getFormatFromFile(file);

        setValues({ ...values, file, format });
    };

    const handleSubmit = (event: any) => {
        event.preventDefault();

        if (!values.file) {
            return;
        }

        setLoading(true);
        snackbarStore.close();

        const formData = new FormData();
        formData.append("format", values.format);
        formData.append("source", values.source);
        formData.append("file", values.file!!, values.file!!.name);

        bulkUpload(formData)
            .then(result => {
                setLoading(false);

                onSubmit(Promise.resolve(result));
            })
            .catch(reason => {
                reason.then((error: any) => {
                    setLoading(false);

                    snackbarStore.open(false);
                });
            });
    };

    useEffect(() => {
        (async () => {
            setConfigLoading(true);

            const result = await getBulkConfig();
            setConfig(result);

            setConfigLoading(false);
        })();
    }, []);

    if (configLoading || !config) {
        return (
            <CircularProgress size={40} />
        );
    }

    return (
        <FormWrapper onSubmit={handleSubmit} loading={loading}>
            {values.region !== '' &&
                <Grid item xs={12}>
                    <SelectField
                        label={t('transactions.bulk.upload_form.source')}
                        options={config!!.regions.find(r => r.region === values.region)?.sources.map(s => s.source) || []}
                        value={values.source}
                        onChange={handleChange('source')}
                        optionValueGetter={region => region}
                        optionLabelGetter={region => LABELS[region] || region}
                    />
                </Grid>
            }

            {values.source !== '' &&
                <Grid item xs={12}>
                    <FileField
                        formats={config!!.regions.find(r => r.region === values.region)?.sources.find(s => s.source === values.source)!!.available_formats || []}
                        selectedFile={values.file as any}
                        onChange={handleFileChange}
                    />
                </Grid>
            }

            <Grid item xs={12}>
                <Button
                    disabled={!values.file}
                    type="submit"
                    onClick={handleSubmit}
                    variant="contained"
                    color="primary"
                >
                    {t('transactions.bulk.upload_form.next')}
                </Button>
            </Grid>
        </FormWrapper>
    )
}
