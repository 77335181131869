import * as React from "react";
import TableRow from "@mui/material/TableRow";
import TableHead from "@mui/material/TableHead";
import TableCell from "@mui/material/TableCell";
import {styled} from "@mui/material";

const StyledTableHead = styled(TableHead)(() => ({
    "& .MuiTableCell-root.MuiTableCell-head": {
        fontWeight: 'bold',
    }
}));

const ActionsCell = styled(TableCell)(() => ({
    paddingLeft: 0,
    paddingRight: 0,
    textAlign: 'center',
}));

const DateCell = styled(TableCell)(() => ({
    width: 110,
}));

const AmountCell = styled(TableCell)(() => ({
    width: 110,
}));

export const TableHeader = () => (
    <StyledTableHead>
        <TableRow>
            <ActionsCell>Actions</ActionsCell>
            <DateCell>Date</DateCell>
            <AmountCell>Amount</AmountCell>
            <TableCell>Description</TableCell>
        </TableRow>
    </StyledTableHead>
);
