import * as React from "react";
import {Box, LinearProgress, styled, Typography} from "@mui/material";
import {DataGrid, GridColumnHeaders} from "@mui/x-data-grid";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import {fetchLocations} from "../stores/locations";
import {ILocation, LocationVendor} from "../../../config/location";
import {useState} from "react";
import {GridRenderCellParams} from "@mui/x-data-grid/models/params/gridCellParams";
import {LOCATION_VENDOR} from "./config";
import {useGlobalStores} from "../../../hooks/use_global_stores";
import {GridRowParams} from "@mui/x-data-grid/models/params/gridRowParams";
import {Pagination} from "../../Transactions/Components/List/Pagination";
import {UploadLogoAction} from "./Actions/UploadLogoAction";
import {Icon} from "../../Components/Icons/Icon";
import {CreateAction} from "./Actions/CreateAction";
import {EditAction} from "./Actions/EditAction";
import {SnackbarStore} from "../../../stores/snackbar";
import {MergeAction} from "./Actions/MergeAction";
import {GridRowSelectionModel} from "@mui/x-data-grid/models/gridRowSelectionModel";
import {GridCallbackDetails} from "@mui/x-data-grid/models/api";
import { PopulateLogosAction } from "./Actions/PopulateLogosAction";

const LogoWrapper = styled(Box)(({theme}) => ({
    display: 'flex',
    alignItems: 'center',
    width: 90,
    height: 40,
}));

const ActionsWrapper = styled(Box)(({theme}) => ({
    marginTop: 10,
    marginBottom: 20,

    '> *': {
        marginRight: 10,
    }
}));

export const AtlasLocations = () => {
    const { categoryStore, snackbarStore } = useGlobalStores();

    const [rows, setRows] = React.useState<ILocation[]>([]);
    const [rowsTotal, setRowsTotal] = React.useState<number>(0);
    const [paginationModel, setPaginationModel] = React.useState({
        page: 0,
        pageSize: 100,
    });
    const [selectionModel, setSelectionModel] = useState<GridRowSelectionModel>([]);
    const [loading, setLoading] = useState<boolean>(false);

    React.useEffect(() => {
        setLoading(true);
        const fetcher = async () => {
            // fetch data from server
            const data = await fetchLocations({
                page: paginationModel.page + 1,
            });
            setRows(data.locations);
            setRowsTotal(data.total);
            setLoading(false);
        };
        fetcher();
    }, [paginationModel]);

    const handleSelect = (rowSelectionModel: GridRowSelectionModel, _: GridCallbackDetails) => {
        setSelectionModel(rowSelectionModel);
    };

    const handleCreate = (location: ILocation) => {
        snackbarStore.open(SnackbarStore.SUCCESS, 'Location created successfully');

        setRows((rows) => [location, ...rows]);
    };

    const handleChange = (id: number, newValue: ILocation | null) => {
        if (newValue === null) {
            snackbarStore.open(SnackbarStore.SUCCESS, 'Location deleted successfully');

            setRows(rows.filter((row) => row.id !== id));
            return;
        }

        snackbarStore.open(SnackbarStore.SUCCESS, 'Location changed successfully');

        setRows(rows.map((row) => (row.id === id ? newValue : row)));
    };

    const handleMerge = (mainId: number, mergedIds: number[]) => {
        snackbarStore.open(SnackbarStore.SUCCESS, 'Locations merged successfully');

        setSelectionModel([]);
        setRows(rows.filter((row) => row.id! === mainId || mergedIds.indexOf(row.id!) === -1));
        return;
    };

    return (
        <div>
            <Typography variant="h4">Locations</Typography>

            <ActionsWrapper>
                <PopulateLogosAction />
                <CreateAction onCreate={handleCreate} />

                {!!selectionModel.length && selectionModel.length > 1 && (
                    <MergeAction
                        locations={rows.filter(v => selectionModel.indexOf(v.id!) > -1)}
                        onSuccess={handleMerge}
                    />
                )}
            </ActionsWrapper>

            <DataGrid
                autoHeight
                disableColumnMenu
                pageSizeOptions={[paginationModel.pageSize]}
                paginationModel={paginationModel}
                onPaginationModelChange={setPaginationModel}
                checkboxSelection
                disableRowSelectionOnClick
                onRowSelectionModelChange={handleSelect}
                rowSelectionModel={selectionModel}
                columns={[
                    {
                        field: 'actions',
                        headerName: 'Actions',
                        type: 'actions',
                        getActions: (params: GridRowParams<ILocation>) => {
                            return [
                                <> {/* necessary to suppress a dom error */}
                                    <div>
                                        <UploadLogoAction location={params.row} onChange={handleChange} />
                                        <EditAction location={params.row} onChange={handleChange} />
                                        {/*<DeleteAction location={params.row} onDelete={handleChange} />*/}
                                    </div>
                                </>
                            ];
                        },
                    },
                    {
                        field: 'id',
                        headerName: 'ID',
                        filterable: false,
                        width: 70,
                        renderCell: (params: GridRenderCellParams<ILocation, any , any>) => (
                            <strong>
                                {params.value}
                            </strong>
                        ),
                    },
                    {
                        field: 'logo',
                        headerName: 'Logo',
                        sortable: false,
                        filterable: false,
                        width: 100,
                        renderCell: (params: GridRenderCellParams<ILocation, any , any>) => (
                            <LogoWrapper>
                                {!!params.value && <Icon file={params.value} absolutePath style={{maxWidth: 90, maxHeight: 40}} />}
                            </LogoWrapper>
                        ),
                    },
                    {
                        field: 'name',
                        headerName: 'Name',
                        width: 250,
                        renderCell: (params: GridRenderCellParams<ILocation, any , any>) => (
                            <div>
                                <div>{params.value}</div>
                                {params.row.address && <div style={{fontSize: 10}}>{params.row.address}</div>}
                            </div>
                        ),
                    },
                    {
                        field: 'vendor',
                        headerName: 'Vendor',
                        width: 250,
                        renderCell: (params: GridRenderCellParams<ILocation, any , any>) => (
                            <div>
                                <div>{LOCATION_VENDOR[params.value as LocationVendor]}</div>
                                {params.row.vendor_id && <div style={{fontSize: 10}}>ID: {params.row.vendor_id}</div>}
                            </div>
                        ),
                    },
                    {
                        field: 'default_category_id',
                        headerName: 'Default Category',
                        width: 200,
                        renderCell: (params: GridRenderCellParams<ILocation, any, any>) => {
                            return (
                                <div>
                                    {
                                        !!params.value ?
                                            categoryStore.getById(params.value)?.name.replace(/^\-*\s?(.+)/, '$1') :
                                            <span style={{color: '#bbb'}}>n/a</span>
                                    }
                                </div>
                            );
                        },
                    },
                ]}
                rows={rows}
                rowCount={rowsTotal}
                loading={loading}
                pagination
                paginationMode="server"
                slots={{
                    columnHeaders: GridColumnHeaders,
                    columnSortedDescendingIcon: () => <ExpandMoreIcon className="icon" />,
                    columnSortedAscendingIcon: () => <ExpandLessIcon className="icon" />,
                    loadingOverlay: LinearProgress,
                    pagination: Pagination,
                }}
             />
        </div>
    );
};
