import * as React from "react";
import {createRecurringExpense} from "../../../stores/recurring_expense";
import {SnackbarStore} from "../../../stores/snackbar";
import {IRecurringExpenseFormOutputData, RecurringExpenseForm} from "../Form/RecurringExpenseForm";
import {convertFormOutputDataToRecurringExpenseCreate} from "../../../helpers/recurring_expenses_helper";
import {useGlobalStores} from "../../../hooks/use_global_stores";
import {IRecurringExpense} from "../../../config/recurring_expense";
import {useRecurringExpenseStore} from "../../../hooks/use_recurring_expense_store";
import { FormModal } from "../../Components/FormModal";
import { Button, useMediaQuery, useTheme } from "@mui/material";
import { AddCircle } from "@mui/icons-material";
import { useModal } from "../../../hooks/use_modal";

export const NewRecurringExpense = () => {
    const recurringExpensesStore = useRecurringExpenseStore();
    const { snackbarStore } = useGlobalStores();
    
    const { open, handleModalOpen, handleModalClose } = useModal();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.up('xl'));

    const handleSubmit = (data: IRecurringExpenseFormOutputData): Promise<any> => {
        return createRecurringExpense(convertFormOutputDataToRecurringExpenseCreate(data));
    };

    const handleSuccess = (res: IRecurringExpense) => {
        recurringExpensesStore.addRecurringExpense(res);

        snackbarStore.open(SnackbarStore.SUCCESS, 'New recurring expense added successfully!');
        
        handleModalClose();
    };

    return (
        <>
            <FormModal
                title="New recurring expense"
                open={open}
                loading={false}
                onClose={handleModalClose}
            >
                <RecurringExpenseForm onSubmit={handleSubmit} onSuccess={handleSuccess} />
            </FormModal>

            {<Button
                size="small"
                startIcon={<AddCircle />}
                onClick={handleModalOpen}
            >
                {fullScreen ? "Add new recurring expense" : "Add"}
            </Button>}
        </>
    );
};
