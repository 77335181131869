import * as React from "react";

interface ILocationFieldProps {
    locationName?: string | null;
}

export const LocationField = ({locationName}: ILocationFieldProps) => {
    if (!locationName) {
        return null;
    }

    return (
        <span>{locationName}</span>
    )
};
