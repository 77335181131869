import * as React from "react";
import TableRow from "@mui/material/TableRow";
import TableHead from "@mui/material/TableHead";
import TableCell from "@mui/material/TableCell";
import {styled, useMediaQuery, useTheme} from "@mui/material";
import { NewRecurringExpense } from "../../New/NewRecurringExpense";

const StyledTableHead = styled(TableHead)(() => ({
    "& .MuiTableCell-root.MuiTableCell-head": {
        fontWeight: 'bold',
    }
}));

const ActionsCell = styled(TableCell)(() => ({
    paddingLeft: 0,
    paddingRight: 0,
    textAlign: 'center',
}));

const PaymentMethodCell = styled(TableCell)(({theme}) => ({
    [theme.breakpoints.down('sm')]: {
        display: 'none',
    },
}));

const CategoryCell = styled(TableCell)(({theme}) => ({
    [theme.breakpoints.down('sm')]: {
        display: 'none',
    },
}));

const LocationCell = styled(TableCell)(({theme}) => ({
    [theme.breakpoints.down('md')]: {
        display: 'none',
    },
}));

export const TableHeader = () => {
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.up('xl'));
    
    return (
        <StyledTableHead>
            <TableRow>
                <ActionsCell>
                    {!fullScreen && <NewRecurringExpense />}
                </ActionsCell>
                <TableCell>Description</TableCell>
                <TableCell>Amount</TableCell>
                <PaymentMethodCell>Payment Method</PaymentMethodCell>
                <CategoryCell>Category</CategoryCell>
                <LocationCell>Location</LocationCell>
            </TableRow>
        </StyledTableHead>
    );
};
