import * as React from "react";
import {ExtraIncomeForm} from "../Form/ExtraIncomeForm";
import {FormModal} from "../../Components/FormModal";
import {IExtraIncomeFormData} from "../../../config/extra_income";
import {useGlobalStores} from "../../../hooks/use_global_stores";
import {createExtraIncome} from "../../../stores/extra_income";
import {SnackbarStore} from "../../../stores/snackbar";
import { useModal } from "../../../hooks/use_modal";
import { AddCircle } from "@mui/icons-material";
import { Button } from "@mui/material";

export const NewExtraIncome = () => {
    const { refreshStore, snackbarStore } = useGlobalStores();

    const { open, handleModalOpen, handleModalClose, loading, setLoading } = useModal();

    const handleSubmit = (data: IExtraIncomeFormData) => {
        setLoading(true);

        createExtraIncome(data)
            .then(() => {
                setLoading(false);

                refreshStore.refreshExtraIncomes();
                snackbarStore.open(SnackbarStore.SUCCESS, 'Extra income added successfully!');

                handleModalClose();
            })
            .catch(() => {
                setLoading(false);

                snackbarStore.open(SnackbarStore.FAIL);

                handleModalClose();
            });
    };

    return (
        <>
            <Button
                size="small"
                startIcon={<AddCircle />}
                onClick={handleModalOpen}
            >
                Add new extra income
            </Button>

            <FormModal
                title="New extra income"
                loading={loading}
                open={open}
                onClose={handleModalClose}
            >
                <ExtraIncomeForm loading={loading} onSubmit={handleSubmit} />
            </FormModal>
        </>
    );
};
