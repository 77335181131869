import * as React from "react";
import { IconButton } from "@mui/material";
import { Edit } from "@mui/icons-material";
import { IRecurringExpense } from "../../../../config/recurring_expense";
import { useRecurringExpenseStore } from "../../../../hooks/use_recurring_expense_store";
import { useGlobalStores } from "../../../../hooks/use_global_stores";
import { useModal } from "../../../../hooks/use_modal";
import { FormModal } from "../../../Components/FormModal";
import { IRecurringExpenseFormOutputData, RecurringExpenseForm } from "../../Form/RecurringExpenseForm";
import { SnackbarStore } from "../../../../stores/snackbar";
import { updateRecurringExpense } from "../../../../stores/recurring_expense";
import { convertFormOutputDataToRecurringExpenseUpdate } from "../../../../helpers/recurring_expenses_helper";

interface IEditActionProps {
    recurringExpense: IRecurringExpense;
}

export const EditAction = ({recurringExpense}: IEditActionProps) => {
    const recurringExpensesStore = useRecurringExpenseStore();
    const { snackbarStore } = useGlobalStores();
    
    const { open, handleModalOpen, handleModalClose } = useModal();
    
    const [value, setValue] = React.useState<IRecurringExpense>();
    const [loading, setLoading] = React.useState(true);

    const handleSubmit = (data: IRecurringExpenseFormOutputData): Promise<any> => {
        return updateRecurringExpense(recurringExpense.id!, convertFormOutputDataToRecurringExpenseUpdate(data));
    };

    const handleSuccess = (res: IRecurringExpense) => {
        recurringExpensesStore.replaceRecurringExpense(res);
        if (
            res.time_frequency !== recurringExpense!.time_frequency ||
            res.amount !== recurringExpense!.amount ||
            res.currency !== recurringExpense!.currency
        ) {
            recurringExpensesStore.shouldStatsBeReloaded = true;
        }

        snackbarStore.open(SnackbarStore.SUCCESS, 'Recurring expense modified successfully!');
        handleModalClose();
    };

    React.useEffect(() => {
        setLoading(true);

        (async () => {
            if (!recurringExpensesStore.shouldBePopulated) {
                setValue(recurringExpensesStore.findById(recurringExpense.id)!);
            } else {
                alert('damn! :( recurring expense not found')
            }

            setLoading(false);
        })();
    }, [recurringExpense.id]);

    return (
        <>
            <FormModal
                title="Edit recurring expense"
                open={open}
                loading={loading}
                onClose={handleModalClose}
            >
                <RecurringExpenseForm recurringExpense={value} onSubmit={handleSubmit} onSuccess={handleSuccess} />
            </FormModal>

            <IconButton size="small" color="primary" onClick={handleModalOpen}>
                <Edit />
            </IconButton>
        </>
    );
};
