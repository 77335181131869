import * as React from "react";
import {IRecurringExpense} from "../../../config/recurring_expense";
import {TimeFrequencyField} from "./Fields/TimeFrequencyField";
import {PaymentMethodField} from "./Fields/PaymentMethodField";
import {CategoryField} from "./Fields/CategoryField";
import {FreeTextField} from "../../Components/Form/FreeTextField";
import {CurrencyField} from "../../Components/Form/CurrencyField";
import {MoneyField} from "../../Components/Form/MoneyField";
import {toFormData, toFormRequest} from "./config/helpers";
import {useGlobalStores} from "../../../hooks/use_global_stores";
import {Currency} from "../../../config/currencies";
import {SnackbarStore} from "../../../stores/snackbar";
import { Form } from "../../Components/Form/Form/Form";
import { useForm } from "../../../hooks/use_form_v2";
import { IncomeField } from "./Fields/IncomeField";
import { ILocation } from "../../../config/location";
import { LocationFieldAsync } from "../../Components/Form/LocationField/LocationFieldAsync";
import { WithdrawTimeField } from "./Fields/WithdrawTimeField";

interface IRecurringExpenseFormProps {
    recurringExpense?: IRecurringExpense;
    onSubmit(data: IRecurringExpenseFormOutputData, additionalData?: any): Promise<any>;
    onSuccess?(data: IRecurringExpense): void;
}

export interface IRecurringExpenseFormState {
    incomeId?: number;
    description: string;
    currency: Currency;
    amount: string;
    timeFrequency: string;
    categoryId: string;
    location: ILocation | null;
    paymentMethodId: string;
    active: boolean;
    withdrawTime: string;
}

export interface IRecurringExpenseFormOutputData {
    income_id?: number;
    description: string;
    amount: number;
    currency: string;
    time_frequency: string;
    category_id: number | null;
    location_id: number | null;
    payment_method_id: number | null;
    active: boolean;
    withdraw_time: number | null;
}

export const RecurringExpenseForm = ({recurringExpense, onSubmit, onSuccess}: IRecurringExpenseFormProps) => {
    const { snackbarStore, userStore } = useGlobalStores();

    const form = useForm<IRecurringExpenseFormState>(toFormData(userStore.currentUser!, recurringExpense));
    const { register, watch } = form;

    const handleSubmit = (values: IRecurringExpenseFormState) => {
        return onSubmit(toFormRequest(values))
            .then(res => onSuccess && onSuccess(res))
            .catch(reason => {
                snackbarStore.open(SnackbarStore.FAIL);
                return Promise.reject(reason);
            });
    };

    return (
        <Form form={form} onSubmit={handleSubmit}>
            <Form.Field type={CurrencyField} {...register('currency')} gridProps={{xs: 4, md: 3, lg: 2}} />
            <Form.Field type={MoneyField} {...register('amount')} currency={watch('currency')} gridProps={{xs: 8, md: 9, lg: 10}} />
            <Form.Field type={FreeTextField} {...register('description')} />
            <Form.Field type={TimeFrequencyField} {...register('timeFrequency')} />
            <Form.Field type={WithdrawTimeField} {...register('withdrawTime')} noWrapper frequency={watch('timeFrequency')} />
            <Form.Field type={PaymentMethodField} {...register('paymentMethodId')} />
            <Form.Field type={LocationFieldAsync} {...register('location')} withGeoLocationAction={false} withGoogleMapsLocationFinder={false} />
            <Form.Field type={CategoryField} {...register('categoryId')} />
            <Form.Field type={IncomeField} {...register('incomeId')} />
        </Form>
    );
};
