import * as React from "react";
import {styled, Table, TableBody, TableCell, TableContainer, TableRow} from "@mui/material";
import { TableHeader } from "./Components/TableHeader";
import { IExtraIncome } from "../../../config/extra_income";
import { DeleteAction } from "../Actions/DeleteAction";
import { EditAction } from "../Actions/EditAction";
import { YearSeparator } from "./Components/YearSeparator";

const ActionsCell = styled(TableCell)(() => ({
    whiteSpace: 'nowrap',
    paddingRight: 0,
    width: 10,
    textAlign: 'center',
}));

interface IDataTableProps {
    data: IExtraIncome[];
}

export const DataTable = ({data}: IDataTableProps) => {
    let currentYear = '';
    let separator = false;

    return (
        <TableContainer>
            <Table size="small">
                <TableHeader />
                <TableBody>
                    {data.map((item, index) => {
                        if (!currentYear) {
                            currentYear = item.date.format('YYYY');
                        }
                        if (currentYear === item.date.format('YYYY')) {
                            separator = false;
                        } else {
                            separator = true;
                            currentYear = item.date.format('YYYY');
                        }

                        return (
                            <>
                                {separator && <YearSeparator />}
                                <TableRow key={index}>
                                    <ActionsCell>
                                        <EditAction extraIncome={item} />
                                        <DeleteAction extraIncome={item} />
                                    </ActionsCell>
                                    <TableCell>{item.date.format('DD/MM/YYYY')}</TableCell>
                                    <TableCell>{(item.amount as any).toCurrency(item.currency as any)}</TableCell>
                                    <TableCell>{item.description}</TableCell>
                                </TableRow>
                            </>
                        );
                    })}
                </TableBody>
            </Table>
        </TableContainer>
    )
};
