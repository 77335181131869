import moment from "moment";
import { IBudgetState } from "../components/Settings/Budgets/Form/BudgetForm";
import { IBudget, IBudgetDetails, IBudgetRequest } from "../config/budget";
import { IMuiSelectOption } from "../config/mui";
import { convertAmountToFloat } from "./transaction_helpers";

export const transformToMuiSelectFormat = (data: IBudget[]): IMuiSelectOption[] => {
    return data.map(({id, name}) => {
        return {value: id, label: name};
    });
};

export const toForm = (data: IBudgetState): IBudgetRequest => {
    return {
        name: data.name,
        amount: convertAmountToFloat(data.amount),
    }
};

export const fillMonths = (input: IBudgetDetails, year: number): any => {
    if (!input.usages.length) {
        return [];
    }

    const res = [];
    for (let i = 1; i <= 12; i++) {
        res[i-1] = [
            moment(`${year}-${i < 10 ? `0${i}` : i}`, 'YYYY-MM'),
            0,
            input.amount,
        ];
    }

    input.usages.filter((item) => item.from.year() === year).forEach((item) => {
        res[item.from.month()] = [
            item.from,
            item.amount_used,
            input.amount,
        ];
    });

    return res;
};

export const getChartMaxHeight = (input: IBudgetDetails) => {
    return Math.max(input.amount, ...(input.usages.map(item => item.amount_used))) + 50
};
