import * as React from "react";
import {ExtraIncomeForm} from "../Form/ExtraIncomeForm";
import {FormModal} from "../../Components/FormModal";
import {IExtraIncome, IExtraIncomeFormData} from "../../../config/extra_income";
import {useExtraIncomeStore} from "../../../hooks/use_extra_income";
import {updateExtraIncome} from "../../../stores/extra_income";
import {useGlobalStores} from "../../../hooks/use_global_stores";
import {SnackbarStore} from "../../../stores/snackbar";
import { useModal } from "../../../hooks/use_modal";
import { IconButton } from "@mui/material";
import { Edit } from "@mui/icons-material";

interface IEditAction {
    extraIncome: IExtraIncome;
}

export const EditAction = ({extraIncome}: IEditAction) => {
    const extraIncomeStore = useExtraIncomeStore();
    const { snackbarStore } = useGlobalStores();

    const { open, handleModalOpen, handleModalClose, loading, setLoading } = useModal();

    const handleSubmit = (data: IExtraIncomeFormData) => {
        setLoading(true);

        updateExtraIncome(extraIncome.id, data)
            .then(result => {
                setLoading(false);

                extraIncomeStore.replaceExtraIncome(result);
                snackbarStore.open(SnackbarStore.SUCCESS, 'Extra income updated successfully!');

                handleModalClose();
            })
            .catch(() => {
                setLoading(false);

                snackbarStore.open(SnackbarStore.FAIL);

                handleModalClose();
            });
    };

    return (
        <>
            <IconButton size="small" color="primary" onClick={handleModalOpen}>
                <Edit />
            </IconButton>
            
            <FormModal
                title="Edit extra income"
                loading={loading}
                open={open}
                onClose={handleModalClose}
            >
                <ExtraIncomeForm loading={loading} extraIncome={extraIncome} onSubmit={handleSubmit} />
            </FormModal>
        </>
    );
};
