import * as React from "react";
import { Form } from "../../../Components/Form/Form/Form";
import { SelectField } from "../../../Components/Form/SelectField";
import { RecurringExpensesTimeFrequency } from "../../../../config/recurring_expense";

interface IWithdrawTimeFieldProps {
    frequency?: RecurringExpensesTimeFrequency;
}

export const WithdrawTimeField = ({frequency, ...props}: IWithdrawTimeFieldProps) => {
    if (frequency !== RecurringExpensesTimeFrequency.YEARLY) {
        return null;
    }

    const months = [
        'Select...',
        'January',
        'February',
        'March',
        'April',
        'May',
        'June',
        'July',
        'August',
        'September',
        'October',
        'November',
        'December',
    ];

    return (
        <Form.Field
            type={SelectField}
            options={months}
            optionValueGetter={(p: any) => months.indexOf(p)}
            optionLabelGetter={(p: any) => p}
            label="Withdraw Time"
            {...props}
        />
    );
};
