import * as React from "react";
import { IBudgetDetails } from "../../../../config/budget";
import { Box, ToggleButton, ToggleButtonGroup } from "@mui/material";

interface IYearSelectionProps {
    budget: IBudgetDetails;
    onChange(year: number): void;
}

export const YearSelection = ({budget, onChange}: IYearSelectionProps) => {
    const [years, setYears] = React.useState<number[]>([]);
    const [value, setValue] = React.useState<number>();

    const handleChange = (_: any, value: number) => {
        setValue(value);
        onChange(value);
    };

    React.useEffect(() => {
        if (!budget) {
            return;
        }

        const tmp = {} as [string: boolean];
        budget.usages.forEach((item) => {
            tmp[item.from.year()] = true;
        });
        setYears(Object.keys(tmp).map(v => parseInt(v)));
    }, [budget]);

    return (
        <Box>
            <ToggleButtonGroup
                exclusive
                size="large"
                value={value}
                onChange={handleChange}
                color="primary"
            >
                {years.map(year => (
                    <ToggleButton key={year} value={year}>{year}</ToggleButton>
                ))}
            </ToggleButtonGroup>
        </Box>
    );
}