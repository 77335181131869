import * as React from "react";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import {alpha, styled} from "@mui/material";

const SeparatorCell = styled(TableCell)(({theme}) => ({
    height: 5,
    padding: 0,
    backgroundColor: alpha(theme.palette.primary.main, 0.3),
}));

export const YearSeparator = () => (
    <TableRow>
        <SeparatorCell colSpan={4} />
    </TableRow>
);
